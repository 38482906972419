<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row mt-sm-2">
        <LeadsDateFilter @update:updateFilters="updateFilters"/>

        <GeoFilter @update:updateFilters="updateFilters"/>

        <ProductNameFilter @update:updateFilters="updateFilters"/>

        <OfferTypeFilter @update:updateFilters="updateFilters"/>

        <LeadsSub1Filter @update:updateFilters="updateFilters"/>

        <LeadsSub2Filter @update:updateFilters="updateFilters"/>

        <LeadsSub3Filter @update:updateFilters="updateFilters"/>

        <LeadsSub4Filter @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import OfferTypeFilter from "@/components/Filters/OfferTypeFilter.vue";
import LeadsDateFilter from "@/components/Filters/LeadsDateFilter.vue";
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue";
import GeoFilter from "@/components/Filters/GeoFilter.vue";
import LeadsSub1Filter from "@/components/Filters/LeadsSub1Filter.vue";
import LeadsSub2Filter from "@/components/Filters/LeadsSub2Filter.vue";
import LeadsSub3Filter from "@/components/Filters/LeadsSub3Filter.vue";
import LeadsSub4Filter from "@/components/Filters/LeadsSub4Filter.vue";

export default {
  name: 'WebCampaignsDashboardFilters',
  components: {
    LeadsSub4Filter,
    LeadsSub3Filter,
    LeadsSub2Filter,
    LeadsSub1Filter,
    GeoFilter,
    ProductNameFilter,
    LeadsDateFilter,
    OfferTypeFilter,
  },
  props: {
    updateFilters: {}
  }
}
</script>
